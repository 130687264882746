import React from 'react';
import { Container, Box, Typography, List, ListItem, ListItemIcon, ListItemText, CssBaseline, ThemeProvider } from '@mui/material';
import PresentationIcon from '@mui/icons-material/School';
import ContentsIcon from '@mui/icons-material/Description';
import ActivitiesIcon from '@mui/icons-material/Edit';
import SummaryIcon from '@mui/icons-material/CheckCircle';
import ResourcesIcon from '@mui/icons-material/LibraryBooks';
import ReferencesIcon from '@mui/icons-material/MenuBook';
import darkTheme from './theme';

function About() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: '#121c28', // Cor de fundo ajustada
          minHeight: '100vh', // Garante que ocupa toda a tela
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5,
        }}
      >
        <Container>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
              mb: 6,
              color: 'white', // Texto branco para contraste
            }}
          >
            <Typography variant="h4" gutterBottom color="white">
              Organização dos Módulos
            </Typography>
            <Typography variant="body1" paragraph color="white" textAlign="center">
              Todos os módulos serão organizados em uma trilha com as seguintes sessões:
            </Typography>
            <List sx={{ width: '100%', maxWidth: 600 }}>
              {[
                {
                  icon: <PresentationIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "APRESENTAÇÃO",
                  secondary: "Breve descrição dos objetivos e tema do módulo",
                },
                {
                  icon: <ContentsIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "CONTEÚDOS",
                  secondary: "Apresenta os conceitos biológicos relevantes para a construção do conhecimento",
                },
                {
                  icon: <ActivitiesIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "ATIVIDADES",
                  secondary: "Atividades interativas para desenvolver os conhecimentos prévios, novas reflexões e realizar a verificação de aprendizagem",
                },
                {
                  icon: <SummaryIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "RESUMO",
                  secondary: "Revisa os principais conteúdos abordados no módulo",
                },
                {
                  icon: <ResourcesIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "RECURSOS",
                  secondary: "Sugestão de material complementar para consulta e aprofundamento do tema do módulo",
                },
                {
                  icon: <ReferencesIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "REFERÊNCIAS",
                  secondary: "Apresenta referencial teórico utilizado para a elaboração dos conteúdos apresentados",
                },
              ].map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    border: `1px solid ${darkTheme.palette.primary.main}`, // Borda na cor principal
                    borderRadius: '8px', // Cantos arredondados
                    mb: 2, // Espaçamento entre os itens
                    padding: '16px', // Espaçamento interno
                    backgroundColor: 'rgba(255, 255, 255, 0.05)', // Fundo levemente translúcido
                  }}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.primary}
                    secondary={item.secondary}
                    primaryTypographyProps={{ color: 'white', fontWeight: 'bold' }}
                    secondaryTypographyProps={{ color: 'white' }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default About;

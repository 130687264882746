import React, { useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Container, CssBaseline, Typography, IconButton, Box, BottomNavigation, BottomNavigationAction, Menu, MenuItem, ListItemText, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Home from './components/Home';
import Login from './components/Login';
import Esqueceu from './components/Esqueceu';
import Register from './components/Register';
import About from './components/About';
import Contact from './components/Contact';
import CrimeSceneInvestigation from './pages/CrimeSceneInvestigation';
import ForensicScience from './pages/ForensicScience';
import BasicGenetics from './pages/BasicGenetics';
import BiologicalSamples from './pages/BiologicalSamples';
import DNAAnalysis from './pages/DNAAnalysis';
import ForensicCases from './pages/ForensicCases';
import DespedidaPage from './pages/DespedidaPage';


import logo from './logo.png'; // Importa a imagem logo

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsAuthenticated(false); // Redefine autenticação
    setAnchorEl(null); // Fecha o menu
    navigate('/'); // Redireciona para a página de login
  };
  
  const showBackButton = location.pathname !== '/' && location.pathname !== '/home';
  const showAppBar = location.pathname !== '/' && location.pathname !== '/register';

  return (
    <div>
      <CssBaseline />
      {showAppBar && (
        <AppBar position="fixed" sx={{ backgroundColor: '#121212' }}>
          <Toolbar>
            {showBackButton && (
              <IconButton edge="start" color="inherit" aria-label="back" onClick={handleBackButton}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              <img src={logo} alt="Logo" style={{ height: '40px' }} />
            </Typography>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen} sx={{ color: '#336b89' }}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => { navigate('/about'); handleMenuClose(); }}>
          <ListItemText primary="Sobre" />
        </MenuItem>
        <MenuItem onClick={() => { navigate('/contact'); handleMenuClose(); }}>
          <ListItemText primary="Fale Conosco" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon />
          <ListItemText primary="Sair" />
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <ListItemText primary="Versão do App: 1.0.0" />
        </MenuItem>
      </Menu>
      {showAppBar && <Toolbar />}
      <Container style={{ marginTop: showAppBar ? '-5px' : '0' }}>
        <Routes>
          <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/crime-scene-investigation" element={<CrimeSceneInvestigation />} />
          <Route path="/forensic-science" element={<ForensicScience />} />
          <Route path="/basic-genetics" element={<BasicGenetics />} />
          <Route path="/biological-samples" element={<BiologicalSamples />} />
          <Route path="/ForensicCases" element={<ForensicCases />} />
          <Route path="/DespedidaPage" element={<DespedidaPage />} />

          <Route path="/dna-analysis" element={<DNAAnalysis />} />
          <Route path="/Esqueceu" element={<Esqueceu />} />
        </Routes>
      </Container>
      {isAuthenticated && (
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              const paths = ['/home', '/about', '/contact', '/crime-scene-investigation', '/forensic-science', '/basic-genetics', '/biological-samples', '/dna-analysis'];
              navigate(paths[newValue]);
            }}
            sx={{ backgroundColor: '#121212' }}  // Cor de fundo
          >
            <BottomNavigationAction 
              label="Home" 
              icon={<HomeIcon />} 
              sx={{ color: '#336b89' }}  // Cor do ícone
            />
            <BottomNavigationAction 
              label="Sobre" 
              icon={<InfoIcon />} 
              sx={{ color: '#336b89' }}  // Cor do ícone
            />
            <BottomNavigationAction 
              label="Fale Conosco" 
              icon={<ContactMailIcon />} 
              sx={{ color: '#336b89' }}  // Cor do ícone
            />
          </BottomNavigation>
        </Box>
      )}
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Container, Box, Link, Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import logo from './logo.png';
import Aviso from './Aviso';
import darkTheme from './theme';

function Login({ setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAviso, setShowAviso] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://athixdev.xyz/helix/login.php', { email, password });
      if (response.data.message === "Login successful") {
        setIsAuthenticated(true);
        if (localStorage.getItem('hasAcceptedTerms') === 'true') {
          navigate('/home');
        } else {
          setShowAviso(true);
        }
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = () => {
    localStorage.setItem('hasAcceptedTerms', 'true');
    setShowAviso(false);
    navigate('/home');
  };

  const handleDecline = () => {
    setShowAviso(true);
  };

  if (showAviso) {
    return <Aviso onAccept={handleAccept} onDecline={handleDecline} />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: '#121c28',
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box', // Evita overflow por padding
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 4,
              width: '100%',
            }}
          >
            <img src={logo} alt="Login" style={{ width: '250px', marginBottom: '30px' }} />
           
            <Box component="form" onSubmit={handleLogin} noValidate sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="filled"
                InputProps={{
                  sx: { backgroundColor: '#fff', borderRadius: 1 },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="filled"
                InputProps={{
                  sx: { backgroundColor: '#fff', borderRadius: 1 },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  p: 1.5,
                  fontSize: '1rem',
                  backgroundColor: '#1976d2',
                  '&:hover': { backgroundColor: '#115293' },
                }}
                disabled={loading}
              >
                {loading ? 'Entrando...' : 'Entrar'}
              </Button>
              <Button
                fullWidth
                variant="text"
                onClick={() => navigate('/esqueceu')}

                sx={{ color: '#1976d2', textTransform: 'none' }}
              >
                Esqueceu a senha?
              </Button>
            </Box>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                mt: 2,
                borderColor: '#fff',
                color: '#fff',
                '&:hover': { borderColor: '#bbb' },
              }}
              onClick={() => navigate('/register')}
            >
              Cadastre-se
            </Button>
            <Link
              href="https://apphelix.com.br/politica-privacidade.html"
              target="_blank"
              rel="noopener"
              sx={{ mt: 3, color: '#1976d2' }}
            >
              Política de Privacidade
            </Link>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Login;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
  Snackbar,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,  // Importar TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import darkTheme from './theme';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#121c28',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

const FormEmbed = styled('iframe')({
  width: '100%',
  height: '700px',
  border: 'none',
  marginTop: '20px',
  borderRadius: '8px',
});

const MuralPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1e1e2d',
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  borderRadius: '8px',
  color: '#ffffff',
}));

const ForensicCases = () => {
  const [moduleData, setModuleData] = useState(null);
  const [formUrl, setFormUrl] = useState('');
  const [mural, setMural] = useState([]);
  const [loading, setLoading] = useState(true);
  const [participate, setParticipate] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // Definir estados para recado
  const [newMessage, setNewMessage] = useState('');
  const [userName, setUserName] = useState('');

  const moduleId = 26; // ID fixo do módulo

  // Buscar módulo ao carregar a página
  useEffect(() => {
    axios
      .get(`https://athixdev.xyz/helix/modules.php?id=${moduleId}`)
      .then((response) => {
        if (response.data.length > 0) {
          setModuleData(response.data[0]);
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar módulo:', error);
      })
      .finally(() => {
        setLoading(false);
      });

    // Buscar recados do mural
    fetchMural();
  }, []);

  // Função para buscar recados do mural
  const fetchMural = async () => {
    try {
      const response = await axios.get(
        `https://athixdev.xyz/helix/mural.php?module_id=${moduleId}`
      );
      setMural(response.data || []);
    } catch (error) {
      showSnackbar('Erro ao buscar recados do mural.', 'error');
    }
  };

  // Função para enviar recado
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') {
      showSnackbar('A mensagem não pode estar vazia.', 'warning');
      return;
    }

    try {
      const response = await axios.post(
        'https://athixdev.xyz/helix/mural.php',
        {
          module_id: moduleId,
          user_name: userName || 'Anônimo',
          message: newMessage,
        }
      );

      showSnackbar(response.data.message, 'success');
      setNewMessage('');
      fetchMural();  // Atualiza mural após envio
    } catch (error) {
      showSnackbar('Erro ao enviar mensagem.', 'error');
    }
  };

  // Função para exibir Snackbar
  const showSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        {loading ? (
          <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
            <CircularProgress />
            <Typography>Carregando módulo...</Typography>
          </Box>
        ) : moduleData ? (
          <BackgroundBox>
            <Heading>{moduleData.title}</Heading>

            {/* Renderizar conteúdo HTML */}
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: moduleData.content }}
              sx={{ marginBottom: 4, color: '#e0e0e0' }}
            />

            <ButtonContainer>
              <Button
                variant="contained"
                color="success"
                onClick={() => setParticipate(true)}
              >
                Vou participar
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setParticipate(false)}
              >
                Não vou participar
              </Button>
            </ButtonContainer>

            {participate === true && formUrl && (
              <FormEmbed src={formUrl} title="Google Form" />
            )}

            {/* Seção do mural - Listagem de recados */}
            <MuralPaper>
              <Typography variant="h6" gutterBottom>
                Mural de Recados
              </Typography>
              <List>
                {mural.length > 0 ? (
                  mural.map((recado) => (
                    <ListItem key={recado.id} divider>
                      <ListItemText
                        primary={recado.message}
                        secondary={`De: ${recado.user_name} - ${new Date(
                          recado.created_at
                        ).toLocaleString('pt-BR')}`}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    Nenhum recado encontrado.
                  </Typography>
                )}
              </List>

              {/* Formulário para adicionar recado */}
              <Typography variant="h6" sx={{ marginTop: 4 }}>
                Deixe seu Recado
              </Typography>
              <TextField
                fullWidth
                label="Nome"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Escreva seu recado"
                multiline
                rows={4}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                sx={{ marginTop: 2 }}
              >
                Enviar Recado
              </Button>
            </MuralPaper>
          </BackgroundBox>
        ) : null}

        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          message={snackbar.message}
        />
      </Container>
    </ThemeProvider>
  );
};

export default ForensicCases;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box, CssBaseline, ThemeProvider } from '@mui/material';
import darkTheme from './theme';
import logo from './logo.png';  // Importando a logo do projeto

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://athixdev.xyz/helix/forgot-password.php', { email });
      if (response.data.message === "Password reset email sent") {
        alert('Email de redefinição enviado! Verifique sua caixa de entrada.');
        navigate('/login');
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao enviar email de redefinição:', error);
      alert('Erro ao tentar enviar o email. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: '#121c28',
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5,
        }}
      >
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* Adicionando a logo no topo */}
            <img src={logo} alt="Logo" style={{ width: '120px', marginBottom: '20px' }} />
            
            <Typography component="h1" variant="h5" color="white">
              Esqueceu a Senha
            </Typography>

            <Box component="form" onSubmit={handleForgotPassword} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Digite seu email"
                name="email"
                autoComplete="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{
                  style: { color: 'white' },
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? 'Enviando...' : 'Enviar'}
              </Button>

              <Button
                fullWidth
                variant="text"
                onClick={() => navigate('/')}
                sx={{
                  color: 'white',
                  border: '1px solid white',
                  mt: 1,
                  mb: 2,
                }}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default ForgotPassword;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech'; // Substituto para DnaIcon
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#121c28' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Helix App
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: '#121c28', // Fundo do menu
              color: 'white', // Texto em branco
            },
          }}
        >
          <MenuItem onClick={handleMenuClose} component={Link} to="/home">
            <HomeIcon sx={{ mr: 1 }} /> Home
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/crime-scene-investigation">
            <SearchIcon sx={{ mr: 1 }} /> Investigando uma Cena de Crime
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/forensic-science">
            <ScienceIcon sx={{ mr: 1 }} /> Perícia Criminal e Genética Forense
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/basic-genetics">
            <BiotechIcon sx={{ mr: 1 }} /> Conceitos Básicos em Genética
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/biological-samples">
            <BiotechIcon sx={{ mr: 1 }} /> Amostras Biológicas e Identificação Humana
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/dna-analysis">
            <BiotechIcon sx={{ mr: 1 }} /> Análises do DNA, Softwares e Bancos de Dados
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/ForensicCases">
            <BiotechIcon sx={{ mr: 1 }} /> Casos Forenses
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/about">
            <InfoIcon sx={{ mr: 1 }} /> Sobre
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/contact">
            <ContactMailIcon sx={{ mr: 1 }} /> Fale Conosco
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;

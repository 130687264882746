import React from 'react';
import { Container, Box, Typography, List, ListItem, ListItemIcon, ListItemText, Link, CssBaseline, ThemeProvider } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import BadgeIcon from '@mui/icons-material/Badge';
import BookIcon from '@mui/icons-material/Book';
import PersonIcon from '@mui/icons-material/Person';
import darkTheme from './theme';

function Contact() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: '#121c28', // Cor de fundo ajustada
          minHeight: '100vh', // Garante altura total da tela
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5,
        }}
      >
        <Container>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
              mb: 6,
              color: 'white', // Texto branco para contraste
            }}
          >
            <Typography variant="h4" gutterBottom color="white">
              Fale Conosco
            </Typography>
            <List sx={{ width: '100%', maxWidth: 600 }}>
              {[
                {
                  icon: <PersonIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "Gabriela C S Campos",
                },
                {
                  icon: <SchoolIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "Graduada em Licenciatura em Ciências Biológicas (UFMT - 2016)",
                },
                {
                  icon: <BadgeIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "Especialista em Perícia Criminal & Ciências Forenses (IPOG - 2021)",
                },
                {
                  icon: <BookIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: "Mestrado profissional em andamento em Ensino de Biologia (UFMT)",
                },
                {
                  icon: <BookIcon sx={{ color: darkTheme.palette.primary.main }} />,
                  primary: (
                    <Link
                      href="http://lattes.cnpq.br/5942774889898875"
                      target="_blank"
                      rel="noopener"
                      sx={{ color: darkTheme.palette.primary.main, textDecoration: 'none' }}
                    >
                      Currículo Lattes
                    </Link>
                  ),
                },
              ].map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    border: `1px solid ${darkTheme.palette.primary.main}`, // Borda na cor principal
                    borderRadius: '8px', // Cantos arredondados
                    mb: 2, // Espaçamento entre itens
                    padding: '16px', // Espaçamento interno
                    backgroundColor: 'rgba(255, 255, 255, 0.05)', // Fundo translúcido
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.primary}
                    primaryTypographyProps={{ color: 'white', fontWeight: 'bold' }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Contact;
